import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getCurrentTime } from "helpers/Utils";
import { Conversation, Users } from "helpers/ChatAPI";

import { getCurrentUser } from "helpers/Utils";

import {
  CHAT_GET_CONTACTS,
  CHAT_GET_GROUPS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
} from "../contants";

import {
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError,
  getGroupsSuccess,
  getGroupsError,
} from "./actions";
import GlideRecord from "helpers/GlideRecord";

function* loadContacts() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadContactsAsync);
    const { contacts, currentUser } = response;
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {
    yield put(getContactsError(error));
  }
}

const loadContactsAsync = async () => {
  const contacts = await Users().then((data) => {
    return data.users.dados;
  });
  // const contacts = contactsData.data;
  const currentUser = getCurrentUser();
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ contacts, currentUser });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadGroups() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadGroupsAsync);
    const { groups } = response;
    console.log(groups);
    yield put(getGroupsSuccess(groups));
  } catch (error) {
    yield put(getGroupsError(error));
  }
}

const loadGroupsAsync = async () => {
  const gr = GlideRecord("groups");
  const groups = await gr.getAll();

  // eslint-disable-next-line no-return-await
  return await new Promise(async (success) => {
    // setTimeout(() => {
    const currentUser = getCurrentUser();

    const _groups = await GlideRecord("groups_users").getByQuery(
      "id_users=" + currentUser.id
    );

    success({
      groups: groups?.record?.filter((g) =>
        _groups?.records?.map((_g) => _g.id_groups).includes(g.id)
      ),
    });
    // }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadConversations(userOrGroupId) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadConversationsAsync, userOrGroupId);
    const { conversations, selectedUserOrGroup } = response;
    yield put(getConversationsSuccess(conversations, selectedUserOrGroup));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

// const loadConversationsAsync = async (payload) =>
// 	await Conversation()
// 	.then((chat) => chat)
// 	.catch((error) => error);

const loadConversationsAsync = async ({ payload }) => {
  let conversations = await Conversation(); //.then(async (data) => {

  conversations = conversations.chats.dados
    .map((chat) => {
      const users = [parseInt(chat.id_sender), parseInt(chat.id_receptor)];

      if (chat.id_receptor.toString().startsWith("1010")) {
        // 1010 === GRUPO
        GlideRecord("groups_users")
          .getByQuery(
            "id_groups=" + chat.id_receptor.toString().replace("1010", "")
          )
          .then((usersFromGroup) =>
            usersFromGroup.records.forEach((u) => users.push(u.id_users))
          );
      }

      return {
        ...chat,
        users,
        messages: JSON.parse(chat.text),
      };
    })
    .reduce((acc, message) => {
      const receptorId = message.id_receptor;

      if (receptorId && receptorId.toString().startsWith("1010")) {
        const existingGroup = acc.find(
          (group) => group.id_receptor === receptorId
        );

        if (existingGroup) {
          existingGroup.messages = existingGroup.messages.concat(
            JSON.parse(message.text)
          );
          existingGroup.messages.sort((a, b) => a.time.localeCompare(b.time));
        } else {
          acc.push({
            ...message,
            messages: JSON.parse(message.text).sort((a, b) =>
              a.time.localeCompare(b.time)
            ),
          });
        }
      } else {
        acc.push(message);
      }

      return acc;
    }, []);

  console.log(conversations);

  const selectedUserOrGroup =
    conversations.length > 0
      ? conversations[0].users.find((x) => x !== payload)
      : 0;

  console.log(selectedUserOrGroup);
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ conversations, selectedUserOrGroup });
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* addMessageToConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, message, allConversations } =
      payload;

    const response = yield call(
      // eslint-disable-next-line no-use-before-define
      addMessageToConversationAsync,
      currentUserId,
      selectedUserId,
      message,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const addMessageToConversationAsync = async (
  currentUserId,
  selectedUserId,
  message,
  allConversations
  // eslint-disable-next-line consistent-return
) => {
  function areMessagesEqual(message1, message2) {
    return (
      message1.sender === message2.sender &&
      message1.time === message2.time &&
      message1.text === message2.text
    );
  }

  const conversation = allConversations.find(
    (x) => x.users.includes(currentUserId) && x.users.includes(selectedUserId)
  );
  const time = getCurrentTime();
  if (conversation) {
    if (
      !!conversation.messages.some((existingMessage) =>
        areMessagesEqual(
          {
            sender: currentUserId,
            time,
            text: message,
          },
          existingMessage
        )
      )
    ) {
      // SE EXISTE COMPLETAMENTE IGUAL NAO DEIXA INSERIR DUPLICADO
      return;
      // await new Promise((success) => {
      //   setTimeout(() => {
      //     success({ conversations, selectedUser: selectedUserId });
      //   }, 500);
      // })
      //   .then((response) => response)
      //   .catch((error) => error);
    }
    conversation.messages.push({
      sender: currentUserId,
      time,
      text: message,
    });
    conversation.lastMessageTime = time;
    const conversations = allConversations.filter(
      (x) => x.id !== conversation.id
    );
    conversations.splice(0, 0, conversation);

    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
      setTimeout(() => {
        success({ conversations, selectedUser: selectedUserId });
      }, 500);
    })
      .then((response) => response)
      .catch((error) => error);
  }
};

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      // eslint-disable-next-line no-use-before-define
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  const conversation = {
    id: allConversations.length + 1,
    users: [currentUserId, selectedUserId],
    lastMessageTime: "-",
    messages: [],
  };

  allConversations.splice(0, 0, conversation);
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({
        conversations: allConversations,
        selectedUser: selectedUserId,
      });
    }, 500);
  })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}

export function* watchGetGroups() {
  yield takeEvery(CHAT_GET_GROUPS, loadGroups);
}

export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}

export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation);
}

export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation),
    fork(watchCreateConversation),
    fork(watchGetGroups),
  ]);
}
